import { createReducer, on } from '@ngrx/store';
import {
  getMerchantDetailByAdminAction,
  getMerchantDetailByAdminErrorAction,
  getMerchantDetailByAdminSuccessAction,
  updateMerchantByAdminErrorAction,
  updateMerchantByAdminSuccessAction,
} from '../actions/merchant-detail.actions';
import { MerchantModel } from '../models/api/merchant.model';
import { StateModel } from '../models/auxiliary/state.model';

export type MerchantDetailState = StateModel<MerchantModel>;

const INITIAL_STATE: MerchantDetailState = {
  errors: null,
  data: null,
};

export const merchantDetailReducer = createReducer(
  INITIAL_STATE,
  on(getMerchantDetailByAdminAction, (state) => ({
    ...INITIAL_STATE,
  })),
  on(getMerchantDetailByAdminSuccessAction, (state, { merchant }) => ({
    errors: null,
    data: merchant,
  })),
  on(getMerchantDetailByAdminErrorAction, (state, { errors }) => ({
    errors: errors,
  })),
  on(updateMerchantByAdminSuccessAction, (state) => ({
    errors: null,
    data: { ...state.data },
  })),
  on(updateMerchantByAdminErrorAction, (state, { errors }) => ({
    errors,
  })),
);
