import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ErrorModel } from '../models/api/error.model';

@Injectable()
export class MessageService {
  constructor(private toastrService: ToastrService) {}

  showError(error: ErrorModel, title: string = 'Generic Error'): void {
    if (error != null) {
      this.toastrService.error(error.message, title + ' #' + error.requestId);
    } else {
      this.toastrService.error('', title);
    }
  }

  showErrorsIfNotNull(errors: ErrorModel[], title: string): boolean {
    if (errors) {
      errors.forEach((error) => this.showError(error, title));
      return true;
    }
    return false;
  }

  showErrors(errors: ErrorModel[], title: string = 'Generic Error'): void {
    if (errors == null) {
      this.error(title);
      return;
    }
    errors.forEach((error) => this.showError(error, title));
  }

  info(message: string): void {
    this.toastrService.info(message, null, {
      timeOut: 8000,
      extendedTimeOut: 15000,
      closeButton: false,
      tapToDismiss: true,
      enableHtml: true,
    });
  }

  error(message: string): void {
    this.toastrService.error(message);
  }

  success(message: string): void {
    this.toastrService.success(message, null, {
      timeOut: 5000,
      extendedTimeOut: 10000,
      closeButton: false,
      tapToDismiss: true,
      enableHtml: true,
    });
  }

  warning(message: string): void {
    this.toastrService.warning(message);
  }
}
