import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  getMerchantDetailByAdminAction,
  getMerchantDetailByAdminErrorAction,
  getMerchantDetailByAdminSuccessAction,
  updateMerchantByAdminAction,
  updateMerchantByAdminErrorAction,
  updateMerchantByAdminSuccessAction,
} from '../actions/merchant-detail.actions';
import { AppStateModel } from '../models/auxiliary/app-state.model';
import { ApiService } from '../services/api.service';
import { MessageService } from '../services/message.service';
import {
  getCurrentRebalancingGroupAction,
  resetCurrentRebalancingGroupAction,
} from '../store/admin/merchants/rebalancing/rebalancing.actions';
import { selectCurrentRebalancingGroupPaging } from '../store/admin/merchants/rebalancing/rebalancing.selectors';
import { ADMIN_MERCHANT_ENDPOINT } from './merchant-page.effect';

@Injectable()
export class MerchantDetailEffect {
  private readonly actions$ = inject(Actions);
  private readonly apiService = inject(ApiService);
  private readonly messageService = inject(MessageService);
  private readonly store = inject(Store<AppStateModel>);

  getMerchantDetailByAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMerchantDetailByAdminAction),
      mergeMap(({ merchantId }) =>
        this.apiService.get(`${ADMIN_MERCHANT_ENDPOINT}/${merchantId}`).pipe(
          map((merchant) => getMerchantDetailByAdminSuccessAction({ merchant })),
          catchError((errors) => observableOf(getMerchantDetailByAdminErrorAction(errors))),
        ),
      ),
    ),
  );

  getMerchantDetailRebalancingGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMerchantDetailByAdminSuccessAction),
      concatLatestFrom(() => this.store.select(selectCurrentRebalancingGroupPaging)),
      map(
        ([
          {
            merchant: { merchantRebalancingGroup },
          },
          { limit, offset },
        ]) =>
          merchantRebalancingGroup == null
            ? resetCurrentRebalancingGroupAction()
            : getCurrentRebalancingGroupAction({ paging: { limit, offset }, groupId: merchantRebalancingGroup }),
      ),
    ),
  );

  updateMerchantByAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMerchantByAdminAction),
      switchMap(({ hashId, merchant }) =>
        this.apiService.patch(`${ADMIN_MERCHANT_ENDPOINT}/${hashId}`, merchant).pipe(
          map(() => updateMerchantByAdminSuccessAction()),
          catchError((errors) => observableOf(updateMerchantByAdminErrorAction(errors))),
        ),
      ),
    ),
  );

  showSuccessMessageOnMerchantUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateMerchantByAdminSuccessAction),
        tap(() => {
          this.messageService.success(`Merchant successfully updated`);
        }),
      ),
    { dispatch: false },
  );

  showErrorMessageOnMerchantUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateMerchantByAdminErrorAction),
        tap(({ errors }) => {
          this.messageService.showErrors(errors, 'Merchant could not be updated.');
        }),
      ),
    { dispatch: false },
  );
}
