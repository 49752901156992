import { DecimalPipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import {
  FullRouterStateSerializer,
  RouterStateSerializer,
  StoreRouterConnectingModule,
  routerReducer,
} from '@ngrx/router-store';
import { Action, ActionReducer, ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import { loginPrefillReducer } from 'app/shared/reducers/login-prefill.reducer';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { localStorageSync } from 'ngrx-store-localstorage';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CUSTOMIZED_DATE_FORMATS, appActions } from './shared/constants';
import { ApiKeyDetailEffect } from './shared/effects/api-key-detail.effect';
import { ApiKeyListEffect } from './shared/effects/api-key-list.effect';
import { AuthEffect } from './shared/effects/auth.effect';
import { BalanceHistoryPageEffect } from './shared/effects/balance-history-page.effect';
import { CallbackPasswordCreateEffect } from './shared/effects/callback-password-create.effect';
import { CallbackPasswordEffect } from './shared/effects/callback-password.effect';
import { ConfigurationListEffect } from './shared/effects/configuration-list.effect';
import { CorrectionTxEffect } from './shared/effects/correction-tx.effect';
import { CryptoAddressVerifyEffect } from './shared/effects/crypto-address-verify.effect';
import { CustomerEmailEffect } from './shared/effects/customer-email.effect';
import { DashboardChartEffect } from './shared/effects/dashboard-chart.effect';
import { EmailInvoicePageEffect } from './shared/effects/email-invoice-page.effect';
import { EmailInvoiceEffect } from './shared/effects/email-invoice.effect';
import { FinancialReportEffect } from './shared/effects/financial-report.effect';
import { InvoiceExpirationFeeEffect } from './shared/effects/invoice-expiration-fee.effect';
import { InvoicePageEffect } from './shared/effects/invoice-page.effect';
import { InvoiceEffect } from './shared/effects/invoice.effect';
import { LedgerEffect } from './shared/effects/ledger.effect';
import { LimitsAndFeesEffect } from './shared/effects/limits-and-fees.effect';
import { LoaderEffects } from './shared/effects/loader.effects';
import { LoginPrefillEffect } from './shared/effects/login-prefill.effect';
import { MarketOperationEffects } from './shared/effects/market-operation.effects';
import { MarketPageEffect } from './shared/effects/market-page.effect';
import { MerchantApiVersionEffect } from './shared/effects/merchant-api-version.effect';
import { MerchantDetailEffect } from './shared/effects/merchant-detail.effect';
import { MerchantDocumentEffect } from './shared/effects/merchant-document.effect';
import { MerchantFeesEffect } from './shared/effects/merchant-fees.effect';
import { MerchantPageEffect } from './shared/effects/merchant-page.effect';
import { MerchantPersonEffect } from './shared/effects/merchant-person.effect';
import { MerchantProfilePageEffect } from './shared/effects/merchant-profile-page.effect';
import { MerchantProfileEffect } from './shared/effects/merchant-profile.effect';
import { MerchantVerificationDocumentsEffects } from './shared/effects/merchant-verification-documents.effects';
import { MerchantVerificationIdenfyEffect } from './shared/effects/merchant-verification-idenfy.effect';
import { MerchantVerificationInfoEffect } from './shared/effects/merchant-verification-info.effect';
import { MerchantVerificationStatusEffects } from './shared/effects/merchant-verification-status.effects';
import { MerchantEffects } from './shared/effects/merchant.effects';
import { NotificationSettingsEffects } from './shared/effects/notification-settings.effects';
import { OpenOrdersStatisticsEffect } from './shared/effects/open-orders-statistics.effect';
import { OtherSideTransactionEffects } from './shared/effects/other-side-transaction.effects';
import { ParameterEffect } from './shared/effects/parameter.effect';
import { PaymentButtonEffects } from './shared/effects/payment-button.effects';
import { PaymentSettingsEffect } from './shared/effects/payment-settings.effect';
import { PayoutFeesEffect } from './shared/effects/payout-fees.effect';
import { RefundPageEffect } from './shared/effects/refund-page.effect';
import { PasswordEffect } from './shared/effects/stateless/password.effect';
import { TwoFactorAuthEffect } from './shared/effects/two-factor-auth.effect';
import { UserDetailEffect } from './shared/effects/user-detail.effect';
import { UserEffect } from './shared/effects/user-effect';
import { UserGuiSettingsEffect } from './shared/effects/user-gui-settings.effect';
import { UserStatsEffect } from './shared/effects/user-stats.effect';
import { WebhookInfoListEffect } from './shared/effects/webhook-info-list.effect';
import { WizardEffects } from './shared/effects/wizard.effects';
import { AuthModel } from './shared/models/api/auth.model';
import { AppStateModel } from './shared/models/auxiliary/app-state.model';
import { RefundTokenMapModel } from './shared/models/auxiliary/customer-token-map.model';
import { apiKeyCreateReducer } from './shared/reducers/api-key-create.reducer';
import { apiKeyDetailReducer } from './shared/reducers/api-key-detail.reducer';
import { apiKeyListReducer } from './shared/reducers/api-key-list.reducer';
import { AuthState, authReducer } from './shared/reducers/auth.reducer';
import { balanceHistoryPageReducer } from './shared/reducers/balance-history-page.reducer';
import { callbackPasswordCreateReducer } from './shared/reducers/callback-password-create.reducer';
import { callbackPasswordReducer } from './shared/reducers/callback-password.reducer';
import { configurationListReducer } from './shared/reducers/configuration-list.reducer';
import { correctionTxListReducer } from './shared/reducers/correction-tx-list.reducer';
import { cryptoAddressVerifyReducer } from './shared/reducers/crypto-address-verify.reducer';
import { customerEmailReducer } from './shared/reducers/customer-email.reducer';
import { dashboardChartReducer } from './shared/reducers/dashboard-chart.reducer';
import { emailInvoicePageReducer } from './shared/reducers/email-invoice-page.reducer';
import { emailInvoiceReducer } from './shared/reducers/email-invoice.reducer';
import {
  financialReportingInvoicesReducer,
  financialReportingPayoutsReducer,
  reportingUserStatsReducer,
} from './shared/reducers/financial-reporting.reducer';
import { InvoiceExpirationFeeReducer } from './shared/reducers/invoice-expiration-fee.reducer';
import { invoicePageReducer } from './shared/reducers/invoice-page.reducer';
import { invoicePreparedCurrenciesReducer } from './shared/reducers/invoice-prepared-currencies.reducer';
import { invoiceReducer } from './shared/reducers/invoice.reducer';
import { ledgerReducer } from './shared/reducers/ledger.reducer';
import { limitsAndFeesReducer } from './shared/reducers/limits-and-fees.reducer';
import { loaderReducer } from './shared/reducers/loader.reducer';
import { marketOperationReducer } from './shared/reducers/market-operation.reducer';
import { marketPageReducer } from './shared/reducers/market-page.reducer';
import { MaxClicksReducer } from './shared/reducers/max-clicks.reducer';
import { merchantApiVersionReducer } from './shared/reducers/merchant-api-version.reducer';
import { merchantDetailReducer } from './shared/reducers/merchant-detail.reducer';
import { merchantDocumentReducer } from './shared/reducers/merchant-document.reducer';
import { merchantFeesReducer } from './shared/reducers/merchant-fees.reducer';
import { merchantPageReducer } from './shared/reducers/merchant-page.reducer';
import { merchantPersonReducer } from './shared/reducers/merchant-person.reducer';
import { merchantProfileAdminReducer } from './shared/reducers/merchant-profile-admin.reducer';
import { merchantProfilePageReducer } from './shared/reducers/merchant-profile-page.reducer';
import { MerchantProfileReducer } from './shared/reducers/merchant-profile.reducer';
import { merchantVerificationDocumentsReducer } from './shared/reducers/merchant-verification-documents.reducer';
import {
  merchantVerificationIdenfyGenerate,
  merchantVerificationIdenfyPersonReducer,
} from './shared/reducers/merchant-verification-idenfy.reducer';
import { merchantVerificationInfoReducer } from './shared/reducers/merchant-verification-info.reducer';
import { merchantVerificationStatusReducer } from './shared/reducers/merchant-verification-status.reducer';
import { merchantReducer } from './shared/reducers/merchant.reducer';
import { notificationSettingsReducer } from './shared/reducers/notification-settings.reducer';
import { openOrdersStatisticsReducer } from './shared/reducers/open-orders-statistics.reducer';
import { otherSideTransactionLimitsReducer } from './shared/reducers/other-side-transaction-limits.reducer';
import { otherSideTransactionListReducer } from './shared/reducers/other-side-transaction-page.reducer';
import { otherSideTransactionReducer } from './shared/reducers/other-side-transaction.reducer';
import { parameterReducer } from './shared/reducers/parameter.reducer';
import { paymentButtonPageReducer } from './shared/reducers/payment-button-page.reducer';
import { paymentButtonReducer } from './shared/reducers/payment-button.reducer';
import { paymentSettingsDefaultReducer } from './shared/reducers/payment-settings-default.reducer';
import { paymentSettingsReducer } from './shared/reducers/payment-settings.reducer';
import { payoutFeesReducer } from './shared/reducers/payout-fees.reducer';
import { pdfSettlementReducer } from './shared/reducers/pdfsettlement.reducer';
import { refundPageReducer } from './shared/reducers/refund-page.reducer';
import { RefundTokenMapState, refundTokenMapReducer } from './shared/reducers/refund-token-map.reducer';
import { twoFactorAuthReducer } from './shared/reducers/two-factor-auth.reducer';
import { userActivationReducer } from './shared/reducers/user-activation.reducer';
import { userDetailReducer } from './shared/reducers/user-detail.reducer';
import { userGuiSettingsReducer } from './shared/reducers/user-gui-settings.reducer';
import { userStatsReducer } from './shared/reducers/user-stats.reducer';
import { userReducer } from './shared/reducers/user.reducer';
import { webhookInfoListReducer } from './shared/reducers/webhook-info-list.reducer';
import { wizardReducer } from './shared/reducers/wizard.reducer';
import { ApiService } from './shared/services/api.service';
import { AppConfigService } from './shared/services/app-config.service';
import { CookiesService } from './shared/services/cookies.service';
import { CustomRouterStoreSerializerService } from './shared/services/custom-router-store-serializer.service';
import { InvoiceSocketService } from './shared/services/invoice-socket.service';
import { IpAddressService } from './shared/services/ip-address.service';
import { IpInfoService } from './shared/services/ip-info.service';
import { LocaleService } from './shared/services/locale.service';
import { LogService } from './shared/services/log-service';
import { MessageService } from './shared/services/message.service';
import { StatisticsSocketService } from './shared/services/statistics-socket.service';
import { TabAccessService } from './shared/services/tab-access.service';
import { XmlReportService } from './shared/services/xml-report.service';
import { SharedModule } from './shared/shared.module';
import { RebalancingEffects } from './shared/store/admin/merchants/rebalancing/rebalancing.effects';
import { rebalancingReducer } from './shared/store/admin/merchants/rebalancing/rebalancing.reducer';
import { AuditLogEventsAdminEffect } from './shared/store/audit-log/admin/audit-log-events-admin.effect';
import { auditLogEventsAdminReducer } from './shared/store/audit-log/admin/audit-log-events-admin.reducer';
import { AuditLogEventsEffect } from './shared/store/audit-log/merchant/audit-log-events.effect';
import { auditLogEventsReducer } from './shared/store/audit-log/merchant/audit-log-events.reducer';
import { MerchantBalanceEffect } from './shared/store/balances/merchant-balance.effect';
import { merchantBalanceReducer } from './shared/store/balances/merchant-balance.reducer';
import { CountryListEffect } from './shared/store/countries/country-list.effect';
import { countryListReducer } from './shared/store/countries/country-list.reducer';
import { cryptoNetworksListReducer } from './shared/store/crypto-networks/crypto-networks-list.reducer';
import { CryptoNetworksEffect } from './shared/store/crypto-networks/crypto-networks.effect';
import { CryptoWalletsStatisticsEffect } from './shared/store/crypto-wallets-statistics/crypto-wallets-statistics.effect';
import { cryptoWalletsStatisticsReducer } from './shared/store/crypto-wallets-statistics/crypto-wallets-statistics.reducer';
import { CurrencyListEffect } from './shared/store/currencies/currency-list.effect';
import { currencyListReducer } from './shared/store/currencies/currency-list.reducer';
import { CurrencyService } from './shared/store/currencies/currency-list.service';
import { FiatPaymentMethodsEffect } from './shared/store/fiat-payment-methods/fiat-payment-methods.effect';
import { fiatPaymentMethodsReducer } from './shared/store/fiat-payment-methods/fiat-payment-methods.reducer';
import { FireblocksVaultAccountEffect } from './shared/store/fireblocks-vault-account/fireblocks-vault-account.effect';
import { invoicePageFiltersReducer } from './shared/store/invoice-page-filters/invoice-page-filters.reducer';
import { ledgerFiltersReducer } from './shared/store/ledger-filters/ledger-filters.reducer';
import { MerchantDirectorsEffect } from './shared/store/merchant-directors/merchant-directors.effect';
import { merchantDirectorsReducer } from './shared/store/merchant-directors/merchant-directors.reducer';
import { paymentButtonListFiltersReducer } from './shared/store/payment-button-list-filters/payment-button-list-filters.reducer';
import { CryptoPaymentMethodsAdminEffect } from './shared/store/payment-methods/admin/crypto-payment-methods-admin.effect';
import { cryptoPaymentMethodsAdminReducer } from './shared/store/payment-methods/admin/crypto-payment-methods-admin.reducer';
import { CryptoPaymentMethodsAdminService } from './shared/store/payment-methods/admin/crypto-payment-methods-admin.service';
import { CryptoPaymentMethodsMerchantEffect } from './shared/store/payment-methods/merchant/crypto-payment-methods-merchant.effect';
import { cryptoPaymentMethodsMerchantReducer } from './shared/store/payment-methods/merchant/crypto-payment-methods-merchant.reducer';
import { CryptoPaymentMethodsMerchantService } from './shared/store/payment-methods/merchant/crypto-payment-methods-merchant.service';
import { CryptoPaymentMethodsPublicEffect } from './shared/store/payment-methods/public/crypto-payment-methods-public.effect';
import { cryptoPaymentMethodsPublicReducer } from './shared/store/payment-methods/public/crypto-payment-methods-public.reducer';
import { CryptoPaymentMethodsPublicService } from './shared/store/payment-methods/public/crypto-payment-methods-public.service';
import { PayoutFeesPublicEffect } from './shared/store/payout-fees-public/payout-fees-public.effect';
import { payoutFeesPublicReducer } from './shared/store/payout-fees-public/payout-fees-public.reducer';
import { payoutPageFiltersReducer } from './shared/store/payout-page-filters/payout-page-filters.reducer';
import { PosDevicesEffect } from './shared/store/pos-devices/pos-devices.effect';
import { posDevicesReducer } from './shared/store/pos-devices/pos-devices.reducer';
import { publicInvoicePreparedCurrencyCodeReducer } from './shared/store/public-invoice-prepared-currency-code/public-invoice-prepared-currency-code.reducer';
import { PublicProfileEffects } from './shared/store/public-profile/public-profile.effects';
import { publicProfileReducer } from './shared/store/public-profile/public-profile.reducer';
import { RefundEffect } from './shared/store/refund/refund.effect';
import { SettlementMethodNotificationEffects } from './shared/store/settlement-method-notifications/settlement-method-notification.effects';
import { settlementMethodNotificationsReducer } from './shared/store/settlement-method-notifications/settlement-method-notifications.reducer';
import { SettlementMethodPageEffect } from './shared/store/settlement-method-page/settlement-method-page.effect';
import { settlementMethodPageReducer } from './shared/store/settlement-method-page/settlement-method-page.reducer';
import { SettlementMethodWizardEffect } from './shared/store/settlement-method-wizard/settlement-method-wizard.effect';
import { settlementMethodWizardReducer } from './shared/store/settlement-method-wizard/settlement-method-wizard.reducer';
import { SettlementMethodEffect } from './shared/store/settlement-method/settlement-method.effect';
import { settlementPageFiltersReducer } from './shared/store/settlement-page-filters/settlement-page-filters.reducer';
import { SettlementPageEffect } from './shared/store/settlement-page/settlement-page.effect';
import { settlementPageReducer } from './shared/store/settlement-page/settlement-page.reducer';
import { SettlementEffect } from './shared/store/settlement/settlement.effect';
import { settlementReducer } from './shared/store/settlement/settlement.reducer';
import { UserRegistrationEffect } from './shared/store/signup/user-registration.effect';
import { SubscriptionDetailEffects } from './shared/store/subscription/subscription-detail/subscription-detail.effects';
import { subscriptionDetailReducer } from './shared/store/subscription/subscription-detail/subscription-detail.reducers';
import { subscriptionListFiltersReducer } from './shared/store/subscription/subscription-list-filters/subscription-list-filters.reducer';
import { SubscriptionListEffects } from './shared/store/subscription/subscription-list/subscription-list.effects';
import { subscriptionListReducer } from './shared/store/subscription/subscription-list/subscription-list.reducer';
import { TodoListEffect } from './shared/store/todo-list/todo-list.effect';
import { todoReducer } from './shared/store/todo-list/todo.reducer';
import { UserListAdminEffects } from './shared/store/user-list-admin/user-list-admin.effects';
import { userListAdminReducer } from './shared/store/user-list-admin/user-list-admin.reducer';
import { UserListEffects } from './shared/store/user-list/user-list.effects';
import { userListReducer } from './shared/store/user-list/user-list.reducer';
import { CryptoAddressValidators } from './shared/validators/crypto-address.validators';

const REDUCERS: ActionReducerMap<AppStateModel> = {
  apiKeyList: apiKeyListReducer,
  apiKeyDetail: apiKeyDetailReducer,
  apiKeyCreate: apiKeyCreateReducer,
  auth: authReducer,
  auditLogEvents: auditLogEventsReducer,
  auditLogEventsAdmin: auditLogEventsAdminReducer,
  cryptoNetworksList: cryptoNetworksListReducer,
  balanceHistoryPage: balanceHistoryPageReducer,
  cryptoWalletsStatistics: cryptoWalletsStatisticsReducer,
  callbackPassword: callbackPasswordReducer,
  callbackPasswordCreate: callbackPasswordCreateReducer,
  countryList: countryListReducer,
  configurationList: configurationListReducer,
  cryptoAddressVerify: cryptoAddressVerifyReducer,
  currencyList: currencyListReducer,
  customerEmail: customerEmailReducer,
  correctionTxList: correctionTxListReducer,
  cryptoPaymentMethodsAdmin: cryptoPaymentMethodsAdminReducer,
  cryptoPaymentMethodsMerchant: cryptoPaymentMethodsMerchantReducer,
  cryptoPaymentMethodsPublic: cryptoPaymentMethodsPublicReducer,
  dashboardChart: dashboardChartReducer,
  emailInvoicePage: emailInvoicePageReducer,
  emailInvoice: emailInvoiceReducer,
  fiatPaymentMethods: fiatPaymentMethodsReducer,
  financialReportInvoices: financialReportingInvoicesReducer,
  financialReportPayouts: financialReportingPayoutsReducer,
  invoice: invoiceReducer,
  invoicePage: invoicePageReducer,
  invoicePageFilters: invoicePageFiltersReducer,
  invoiceExpirationFee: InvoiceExpirationFeeReducer,
  invoicePreparedCurrencies: invoicePreparedCurrenciesReducer,
  idcheckVerificationPerson: merchantVerificationIdenfyPersonReducer,
  idcheckVerificationData: merchantVerificationIdenfyGenerate,
  limitsAndFees: limitsAndFeesReducer,
  loginPrefill: loginPrefillReducer,
  ledger: ledgerReducer,
  ledgerFilters: ledgerFiltersReducer,
  loader: loaderReducer,
  maxClicks: MaxClicksReducer,
  merchant: merchantReducer,
  merchantBalance: merchantBalanceReducer,
  merchantFees: merchantFeesReducer,
  merchantPage: merchantPageReducer,
  merchantDetail: merchantDetailReducer,
  merchantProfile: MerchantProfileReducer,
  merchantVerificationInfo: merchantVerificationInfoReducer,
  merchantProfilePage: merchantProfilePageReducer,
  merchantDocument: merchantDocumentReducer,
  merchantDirectors: merchantDirectorsReducer,
  marketPage: marketPageReducer,
  merchantVerificationStatus: merchantVerificationStatusReducer,
  merchantVerificationDocuments: merchantVerificationDocumentsReducer,
  marketOperation: marketOperationReducer,
  merchantApiVersion: merchantApiVersionReducer,
  merchantPerson: merchantPersonReducer,
  merchantProfileAdmin: merchantProfileAdminReducer,
  notificationSettings: notificationSettingsReducer,
  openOrdersStatistics: openOrdersStatisticsReducer,
  otherSideTransaction: otherSideTransactionReducer,
  otherSideTransactionList: otherSideTransactionListReducer,
  otherSideTransactionLimits: otherSideTransactionLimitsReducer,
  paymentButtonListFilters: paymentButtonListFiltersReducer,
  paymentSettingsDefault: paymentSettingsDefaultReducer,
  paymentSettings: paymentSettingsReducer,
  payoutPageFilters: payoutPageFiltersReducer,
  payoutFeesPublic: payoutFeesPublicReducer,
  pdfSettlement: pdfSettlementReducer,
  payoutFees: payoutFeesReducer,
  paymentButtonPage: paymentButtonPageReducer,
  paymentButton: paymentButtonReducer,
  publicProfile: publicProfileReducer,
  publicInvoicePreparedCurrencyCode: publicInvoicePreparedCurrencyCodeReducer,
  parameter: parameterReducer,
  rebalancingGroup: rebalancingReducer,
  refundPage: refundPageReducer,
  refundTokenMap: refundTokenMapReducer,
  router: routerReducer,
  settlementMethodWizard: settlementMethodWizardReducer,
  settlementMethodPage: settlementMethodPageReducer,
  settlementPage: settlementPageReducer,
  settlementPageFilters: settlementPageFiltersReducer,
  settlementMethodNotifications: settlementMethodNotificationsReducer,
  settlement: settlementReducer,
  twoFactorAuthReducer: twoFactorAuthReducer,
  todoReducer: todoReducer,
  user: userReducer,
  userGuiSettings: userGuiSettingsReducer,
  userList: userListReducer,
  userListAsAdmin: userListAdminReducer,
  userDetail: userDetailReducer,
  userActivation: userActivationReducer,
  userStats: userStatsReducer,
  userStatsReport: reportingUserStatsReducer,
  webhookInfoList: webhookInfoListReducer,
  wizard: wizardReducer,
  posDevices: posDevicesReducer,
  subscriptionList: subscriptionListReducer,
  subscriptionListFilters: subscriptionListFiltersReducer,
  subscriptionDetail: subscriptionDetailReducer,
};

const STORAGE_SYNC_OPTIONS = {
  rehydrate: true,
  removeOnUndefined: true,
  keys: [
    {
      auth: {
        serialize: (state: AuthState): AuthModel =>
          state.data && state.data.authorized ? { token: state.data.token } : undefined,
        deserialize: (data: AuthModel): AuthState => ({
          data: data && { token: data.token, authorized: true },
          errors: null,
        }),
      },
    },
    {
      refundTokenMap: {
        serialize: (state: RefundTokenMapState): RefundTokenMapModel => state.data,
        deserialize: (data: RefundTokenMapModel): RefundTokenMapState => ({ data, errors: null }),
      },
    },
  ],
};

export function appStateReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (appState: AppStateModel, action: Action): AppStateModel {
    const combinedReducer = (syncedState: AppStateModel, syncedAction: Action): AppStateModel => {
      const rehydratedState: AppStateModel = {
        ...syncedState,
        refundTokenMap: { errors: null, data: JSON.parse(localStorage.getItem('refundTokenMap')) },
      };
      const clearedState =
        syncedAction.type === appActions.CLEAR
          ? { auth: rehydratedState.auth, refundTokenMap: rehydratedState.refundTokenMap }
          : rehydratedState;
      return reducer(clearedState, syncedAction);
    };

    return localStorageSync(STORAGE_SYNC_OPTIONS)(combinedReducer)(appState, action);
  };
}

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(
    http,
    './assets/public-invoice/translations/',
    '.json' + '?timestamp=' + new Date().getTime(),
  );
}

const metaReducers: MetaReducer<AppStateModel>[] = [appStateReducer];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    StoreModule.forRoot(REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        // FIXME - current setting is prone to bugs
        // strictStateImmutability: true,
        // strictActionImmutability: true,
        // strictActionSerializability: true,
        // strictStateSerializability: true,
        // strictActionTypeUniqueness: true,
      },
    }),
    StoreDevtoolsModule.instrument({ connectInZone: true }),
    MatMomentDateModule,
    EffectsModule.forRoot([
      ApiKeyListEffect,
      ApiKeyDetailEffect,
      MerchantApiVersionEffect,
      AuthEffect,
      AuditLogEventsEffect,
      AuditLogEventsAdminEffect,
      CryptoNetworksEffect,
      CallbackPasswordEffect,
      CallbackPasswordCreateEffect,
      CountryListEffect,
      CryptoAddressVerifyEffect,
      CryptoPaymentMethodsAdminEffect,
      CryptoPaymentMethodsMerchantEffect,
      CryptoPaymentMethodsPublicEffect,
      CryptoWalletsStatisticsEffect,
      CurrencyListEffect,
      CustomerEmailEffect,
      InvoicePageEffect,
      InvoiceEffect,
      EmailInvoicePageEffect,
      EmailInvoiceEffect,
      FireblocksVaultAccountEffect,
      FiatPaymentMethodsEffect,
      LoginPrefillEffect,
      LimitsAndFeesEffect,
      MerchantFeesEffect,
      UserEffect,
      MerchantBalanceEffect,
      PaymentSettingsEffect,
      LedgerEffect,
      InvoiceExpirationFeeEffect,
      RefundPageEffect,
      SettlementMethodWizardEffect,
      SettlementMethodPageEffect,
      SettlementPageEffect,
      SettlementEffect,
      TodoListEffect,
      TwoFactorAuthEffect,
      WebhookInfoListEffect,
      MerchantPageEffect,
      MerchantDetailEffect,
      MerchantProfileEffect,
      MerchantVerificationInfoEffect,
      MerchantProfilePageEffect,
      MerchantDocumentEffect,
      MerchantPersonEffect,
      MarketPageEffect,
      CorrectionTxEffect,
      ConfigurationListEffect,
      UserGuiSettingsEffect,
      PasswordEffect,
      SettlementMethodEffect,
      RefundEffect,
      UserRegistrationEffect,
      MerchantDirectorsEffect,
      SettlementMethodNotificationEffects,
      MerchantVerificationStatusEffects,
      MerchantVerificationDocumentsEffects,
      WizardEffects,
      LoaderEffects,
      OtherSideTransactionEffects,
      UserListEffects,
      UserListAdminEffects,
      UserDetailEffect,
      NotificationSettingsEffects,
      PaymentButtonEffects,
      PublicProfileEffects,
      MerchantEffects,
      MarketOperationEffects,
      BalanceHistoryPageEffect,
      PayoutFeesEffect,
      PayoutFeesPublicEffect,
      DashboardChartEffect,
      UserStatsEffect,
      FinancialReportEffect,
      ParameterEffect,
      MerchantVerificationIdenfyEffect,
      OpenOrdersStatisticsEffect,
      PosDevicesEffect,
      SubscriptionListEffects,
      SubscriptionDetailEffects,
      RebalancingEffects,
    ]),
    ToastrModule.forRoot({
      timeOut: 30000,
      extendedTimeOut: 0,
      closeButton: true,
      tapToDismiss: false,
    }),

    // Connects RouterModule with StoreModule
    StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
    SharedModule,
    RecaptchaV3Module,
    NgxJsonLdModule,
    HammerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AppConfigService,
    MessageService,
    ApiService,
    LogService,
    IpAddressService,
    IpInfoService,
    InvoiceSocketService,
    StatisticsSocketService,
    XmlReportService,
    CurrencyService,
    CryptoPaymentMethodsMerchantService,
    CryptoPaymentMethodsAdminService,
    CryptoPaymentMethodsPublicService,
    CryptoAddressValidators,
    TabAccessService,
    DecimalPipe,
    MatDatepickerModule,
    CookiesService,
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStoreSerializerService,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: CUSTOMIZED_DATE_FORMATS,
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-US',
    },
    LocaleService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LcU2ucUAAAAAISqu7xrMYE7XiGp5mnvwnzb9F3p',
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot) => {
        window.location.href = (route.data as any).externalUrl;
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
